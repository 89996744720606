/**
 * Global utilities
 * (c) 2012-2017 Ogeane Uk Ltd
 *
 * Work for JQM 1.3.x series
 * V2 : Move to Bootstrap in progress
 * 2.1 Added GenMenu
 */

// function goback () {
//   window.history.go(-1)
// }

function checkCookie_eu()
{

    var consent = getCookie_eu("cookies_consent");

    if (consent == null || consent == "" || consent == undefined || consent=="0")
    {
        // show notification bar
        $('#cookie_directive_container').show();
    }

}

function setCookie_eu(c_name,value,exdays)
{

    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
    document.cookie = c_name + "=" + c_value+"; path=/";

    $('#cookie_directive_container').hide('slow');
}


function getCookie_eu(c_name)
{
    var i,x,y,ARRcookies=document.cookie.split(";");
    for (i=0;i<ARRcookies.length;i++)
    {
        x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
        y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
        x=x.replace(/^\s+|\s+$/g,"");
        if (x==c_name)
        {
            return unescape(y);
        }
    }
}

//---------------------------------------------------------------------------------------------------
function popuppdf( reference, callback ) {
  // if (hpdfpopup == null)
  var hpdfpopup = window.open( reference, 'wogpdf', "height=900, width=1000, left=50, top=50, resizable=yes, scrollbars=yes, toolbar=yes, directories=no, location=no" );
  //else
  //  hpdfpopup.location=reference;
  hpdfpopup.focus();
  if ( typeof callback !== 'undefined' ) {
    window.setTimeout( callback, 1000 );
  }
}

//------------------------------------------------------------------------------
function handleAjaxErrors ( jqXHR ) {
  var returnValue = true;
    if (jqXHR.status == 401) {
      bootbox.confirm("Vous être resté inactif trop longtemps, vous avez été déconnecté du serveur", function () {
        oge.changePage('#'+glb.loginPageId)
        returnValue = false
      })
    }
    if (jqXHR.status == 500) {
      bootbox.confirm("Désolé, une erreur s'est produite sur le serveur nos équipes ont été informées, nous sommes désolés de ce contretemps" , function () {
        oge.changePage('#'+glb.loginPageId)
        returnValue = false;
      })
    }

    return returnValue
}

/*
 * adds options to the mainMenu (class .mainMenu)
 * works for navbar & button-group vertical
 */
function genMenu( menu ) {
  var m = $( '.genmenu' ),
    menunav = m.hasClass( 'navbar-nav' )
  // Cleanup old added menus
  $( '.menuadded', m )
    .remove();
  var html = '';
  menu.forEach( function( element ) {
    var htmlOptions = '';
    element.options.forEach( function( option ) {
      if ( menunav ) {
        htmlOptions += '<a class="dropdown-item ogepage" ' + ' data-local=' + option.local + ' data-pluginid="' + option.pluginid + '" ' + ( option.trans != '' ? ' data-ogtrans="' + option.trans + '" ' : '' ) + ' href="#' + option.link + '">' + option.caption + '</a>';
      } else {
        htmlOptions += '<button data-ogtarget="# ' + option.link + '" '
        ' data-local=' + option.local + ' data-pluginid="' + option.pluginid + '" ' + ' class="mainmenu btn btn-primary menuadded ogepage" ' + ' data-ajax="false" ' + ( option.trans != '' ? 'data-ogtrans="' + option.trans + '" ' : '' ) + '">' + option.caption + '</button>'
      }
    } )
    if ( menunav ) {
      html += '<li class="nav-item dropdown menuadded">' + '<a class="nav-link dropdown-toggle" href="#"  data-toggle="dropdown" ' + ' data-ogtrans="' + ( element.trans ? element.trans : '' ) + 'aria-haspopup="true" aria-expanded="false">' + element.caption + '</a> <div class="dropdown-menu" >' + htmlOptions + '</div></li>'
    } else {
      html += htmlOptions;
    }
  } )
  m.append( html );
}

//
// remove all plugins and menu entries
//

function reloadPlugins() {
  $.each (glb.plugins, function (k) {
    unloadPlugin( k );
  });
}

function removePlugins() {
  // $( '[data-pluginid]' ).each( function( v ) {
  //   var id = $( this ).data( 'pluginid' );
  //   unloadPlugin( id );
  //
  // } );

  $.each (glb.plugins, function (k) {
    unloadPlugin( k );
  });
  $( '[data-pluginid]:not([data-plugin-manual])' ).remove();
  $( '[class*="menuadded"]' ).remove();
}

// Bound id =
// add all id in the fragments. the first Fragment should be the page
// for future removal via unloadPlugin
function initPlugin( pluginId, boundId, fragments ) {
  if ( !glb.plugins[pluginId] ) {
    glb.plugins[pluginId] = {
      status: 'loading',
      fragments: [],
      bound: ''
    };
  }
  glb.plugins[pluginId].bound = boundId;
  if ( !glb.plugins[pluginId].fragments ) {
    glb.plugins[pluginId].fragments = [];
  }

  // rela the rule about plugin bounding element must be an ogepage
  // if ( !$( '#' + boundId )
  //   .hasClass( 'ogepage' ) ) {
  //   console.log( 'warning initPlugin ' + pluginId + ' the bound element ' +
  //     boundId + '  must be the page container ' );
  //   glb.plugins[ pluginId ].fragments = [];
  // } else {
    $.each( fragments, function( k, v ) {
      glb.plugins[pluginId].fragments.push( v );
    } )
  // }
}
//
// unloads a plugin
// 1- Run the _onunload function if available
// 2- Remove the ko bindings from glb.plugins [pluginId].boundId
// 3- Remove the fragments listed in glb.plugins [pluginId].fragments
function unloadPlugin( pluginId ) {
  var docElement, fragments, mainPage
  if (! pluginId )
    return;
  if ( !glb.plugins[ pluginId ] ) return;
  if ( window[ pluginId + '_onunload' ] ) window[ pluginId + '_onunload' ]();
  // Main HTML page
  mainPage = glb.plugins[ pluginId ].bound || '';
  if ( ( mainPage || '' ) != '' ) {
    docElement = document.getElementById( mainPage );
    if ( docElement !== null ) {
      ko.cleanNode( docElement )
    }
  }
  // Fragments éventuels .. devrait être vide
  if ( glb.plugins[ pluginId ] && glb.plugins[ pluginId ].fragments ) fragments = glb.plugins[ pluginId ].fragments.reverse()
  $.each( fragments, function( k, v ) {
    $( '#' + v ).remove();
  } )
  if (mainPage != '')
       $( '#' + mainPage ).remove();


  // supprime l'entrée plugin
  glb.plugins[ pluginId ].model = null
  delete( glb.plugins[ pluginId ] );
  // supprime les évènements de page

  if ( glb.ogeEvents.before[ pluginId ] ) {
    glb.ogeEvents.before[ pluginId ] = null;
    delete glb.ogeEvents.before[ pluginId ];
  }
  if ( glb.ogeEvents.after[ pluginId ] ) {
    glb.ogeEvents.after[ pluginId ] = null;
    delete glb.ogeEvents.after[ pluginId ];
  }

  // glb.oge.changePage( '#' + glb.mainpage );
}
//
// load the plugin HTML & JS if they exist and are accessible
//
function loadPlugin( pluginId, Deferred ) {
  // Check if plugin already loaded
  if ( !glb.plugins ) glb.plugins = {};
   if (! pluginId )  {
     if (Deferred) Deferred.resolve();
     return;
   }

  if ( ( glb.plugins[ pluginId ] ) && ( glb.plugins[ pluginId ].status || '' ) == 'loaded' ) {
    if (Deferred) Deferred.resolve();
    return;
  }
  // unloadPlugin ( pluginId );
  glb.plugins[ pluginId ] = {
    status: 'loading',
    fragments: [],
    bound: ''
  };
  var jsondata = {
      "ogm": "getplugin",
      "ogs": glb.lstore.get( 'usercheck' ),
      "oglinkvalue": pluginId
    },
    $htmldiv = $( '#masterContainer' ),
    $htmlmodal = $( '#masterModal' ),
    $tmpldiv = $( '#masterTemplates' ),
    def = [],
    errors = [],
    regex = /ID\s?=\s?['|"](.*?)["|']/gi;
  // Load the HTML Page
  // it must include IN THE PAGE 1- the modals 2- the script for ko templates
  def.push( $.get( glb.mainurl, $.extend( {}, jsondata, {
      "ogt": "HTML"
    } ), function( data ) {
      if ( data == '' ) {
        console.log( 'plugin: No HTML for ' + pluginId )
      } else {
        $htmldiv.append( data );
        // note handling of fragments must be done
        // inside the plugin_onload function
        //
        //
        //   regex.lastIndex = 0;
        //   var id = regex.exec(data);
        //   if (( id == null ) || (id.length < 2)) {
        //      console.log ('HTML :  Id missing or unrecognised')
        //      errors.push ('HTML :  Id missing or unrecognised')
        //   } else {
        //   glb.plugins [pluginId].fragments.push(id [1]);
        //   $htmldiv.append(data);
        // }
      }
    } )
    .fail( function( jqXHR, textStatus, errorThrown ) {
      console.log( 'HTML:' + textStatus );
      errors.push( 'HTML: ' + textStatus )
    } ) );
    // JS script to be loaded only when all HTML/MODAL/TMPL have been loaded
  $.when.apply( $, def )

   .done( function() {
    //   $.ajax( {
    //       'url': glb.mainurl,
    //       'data': $.extend( {}, jsondata, {
    //         "ogt": "JS"
    //       } ),
    //       'dataType': 'script',
    //       'cache': false
    //     } )
    //     .done( function( data ) {
    //       if ( data == '' ) {
    //         console.log( 'plugin: No HTML for ' + pluginId )
    //       } else {
    //         var sc = $( '<script id=' + pluginId + '_main />', { type: "text/javascript"
    //
    //         } ).append( data );
    //         $( '<body>' ).append( sc );
    //
             console.log ( 'script loaded in '+ pluginId + '_main')
             glb.plugins[ pluginId ].fragments.push( pluginId + '_main' );
             if ( window[ pluginId + '_onload' ] ) window[ pluginId + '_onload' ](); // Starts the initialisation script
             else {
               console.log( pluginId + '_onload: init script not found' )
             }
            glb.plugins[ pluginId ].status = 'loaded';
            if ( Deferred ) Deferred.resolve();
    //       }
    //     } )
    //     .fail( function( jqXHR, textStatus, errorThrown ) {
    //       console.log( 'JS' + textStatus );
    //       errors.push( 'JS' + textStatus );
    //       if ( errors.length > 0 ) {
    //         bootbox.alert( errors.join( '<br />' ) );
    //       }
    //       if ( Deferred ) Deferred.reject();
    //     } )
    } )
}

/** --------------------------------------------------------**/
function refreshPlugin (pluginId) {
  unloadPlugin( pluginId );
  loadPlugin (pluginId);
}
/** --------------------------------------------------------**/
var findEventHandlers = function( eventType, jqSelector ) {
  var results = [];
  var $ = jQuery; // to avoid conflict between others frameworks like Mootools
  var arrayIntersection = function( array1, array2 ) {
    return $( array1 )
      .filter( function( index, element ) {
        return $.inArray( element, $( array2 ) ) !== -1;
      } );
  };
  var haveCommonElements = function( array1, array2 ) {
    return arrayIntersection( array1, array2 )
      .length !== 0;
  };
  var addEventHandlerInfo = function( element, event, $elementsCovered ) {
    var extendedEvent = event;
    if ( $elementsCovered !== void 0 && $elementsCovered !== null ) {
      $.extend( extendedEvent, {
        targets: $elementsCovered.toArray()
      } );
    }
    var eventInfo;
    var eventsInfo = $.grep( results, function( evInfo, index ) {
      return element === evInfo.element;
    } );
    if ( eventsInfo.length === 0 ) {
      eventInfo = {
        element: element,
        events: [ extendedEvent ]
      };
      results.push( eventInfo );
    } else {
      eventInfo = eventsInfo[ 0 ];
      eventInfo.events.push( extendedEvent );
    }
  };
  var $elementsToWatch = $( jqSelector );
  if ( jqSelector === "*" ) //* does not include document and we might be interested in handlers registered there
    $elementsToWatch = $elementsToWatch.add( document );
  var $allElements = $( "*" )
    .add( document );
  $.each( $allElements, function( elementIndex, element ) {

    var allElementEvents = $._data( element, "events" );
    if ( allElementEvents !== void 0 && allElementEvents[ eventType ] !== void 0 ) {
      var eventContainer = allElementEvents[ eventType ];
      $.each( eventContainer, function( eventIndex, event ) {
        var isDelegateEvent = event.selector !== void 0 && event.selector !== null;
        var $elementsCovered;
        if ( isDelegateEvent ) {
          $elementsCovered = $( event.selector, element ); //only look at children of the element, since those are the only ones the handler covers
        } else {
          $elementsCovered = $( element ); //just itself
        }
        if ( haveCommonElements( $elementsCovered, $elementsToWatch ) ) {
          addEventHandlerInfo( element, event, $elementsCovered );
        }
      } );
    }
  } );
  return results;
};
String.prototype.toProperCase = function() {
  return this.toLowerCase()
    .replace( /^(.)|\s(.)/g, function( $1 ) {
      return $1.toUpperCase();
    } );
}
if ( !String.prototype.trim ) {
  String.prototype.trim = function() {
    return this.replace( /^\s+|\s+$/g, '' );
  };
  String.prototype.ltrim = function() {
    return this.replace( /^\s+/, '' );
  };
  String.prototype.rtrim = function() {
    return this.replace( /\s+$/, '' );
  };
  String.prototype.fulltrim = function() {
    return this.replace( /(?:(?:^|\n)\s+|\s+(?:$|\n))/g, '' )
      .replace( /\s+/g, ' ' );
  };
}
/**
 *
 *  Javascript string pad
 *  http://www.webtoolkit.info/
 *
 **/
STR_PAD_LEFT = 1;
STR_PAD_RIGHT = 2;
STR_PAD_BOTH = 3;

function pad( str, len, pad, dir ) {
  if ( typeof( len ) == "undefined" ) {
    var len = 0;
  }
  if ( typeof( pad ) == "undefined" ) {
    var pad = '0';
  }
  if ( typeof( dir ) == "undefined" ) {
    var dir = STR_PAD_LEFT;
  }
  if ( typeof( str ) != 'string' ) str = str.toString();
  if ( len + 1 >= str.length ) {
    switch ( dir ) {
      case STR_PAD_LEFT:
        str = Array( len + 1 - str.length )
          .join( pad ) + str;
        break;
      case STR_PAD_BOTH:
        var right = Math.ceil( ( padlen = len - str.length ) / 2 );
        var left = padlen - right;
        str = Array( left + 1 )
          .join( pad ) + str + Array( right + 1 )
          .join( pad );
        break;
      default:
        str = str + Array( len + 1 - str.length )
          .join( pad );
        break;
    } // switch
  }
  return str;
}
var g = ( function() {
  // put private variables here
  return {
    /**
     * Reset a form for jQuery mobile including possible localstorage save id
     *
     * e can be an object (event) the event target MUST have data-ogformname set  or a string (id of the form)
     *
     * note: on hidden fields
     */
    mbformreset: function( e ) {
      console.log( 'mbformreset: Alert!! cette function utilise toujours JQM; ' )
      return;
      var cnt;
      if ( typeof e == 'string' ) cnt = $( e )
      else {
        e.preventDefault(); // prevent normal button action
        cnt = $( e.target )
          .data( 'ogformname' ) || '';
        if ( cnt === '' ) return;
        cnt = $( '#' + cnt );
      }
      if ( cnt.length === 0 ) return;
      cnt.removeData( 'ogformsaveid' );
      $( 'input, textarea', cnt )
        .not( '[data-type="range"]' )
        .not( '[type="radio"]' )
        .not( '[type=checkbox]' )
        .not( '[type=hidden]' )
        .val( '' ); // clear inputs except buttons, setting value to blank
      // do not use ':hidden' as it brings everything hidden divs, input ...
      $( '[type="hidden"]' )
        .filter( ':not(.ognoreset)', cnt )
        .val( '' );
      // clear select
      $( 'select', cnt )
        .not( ':jqmData(role=slider)' )
        .val( '' )
        .prop( 'selectedIndex', -1 )
        .filter( 'ui-select' )
        .selectmenu();
      // clear slider (ie yes/no type)
      // $('select',cnt).filter(':jqmData(role=slider)').val('0').filter (function()
      //     {alert('slider '+$(this).attr('id')); return $(this).slider ? true : false;}).slider('refresh');
      $( 'select', cnt )
        .filter( ':jqmData(role=slider)' )
        .val( '0' )
        .filter( '.ui-slider-switch' )
        .slider( 'refresh' );
      // range with horizontal line
      $( 'input', cnt )
        .filter( '[data-type="range"]' )
        .val( 0 )
        .filter( function() {
          return $( this )
            .slider ? true : false;
        } )
        .slider( 'refresh' );
      // checkbox and radio button - do not use val() as it clears the value field!!!!!
      $( '[type=checkbox]', cnt )
        .prop( 'checked', false )
        .attr( 'checked', false )
        .each( function() {
          try {
            $( this )
              .checkboxradio( "refresh" );
          } catch ( ex ) {}
        } );
      $( '[type=radio]', cnt )
        .prop( 'checked', false )
        .attr( 'checked', false )
        .each( function() {
          try {
            $( this )
              .checkboxradio( "refresh" );
          } catch ( ex ) {}
        } );
    },
    mbalert: function( msgtext, afterclose ) {
      console.log( 'mbalert: cette function utilise toujours JQM; Voir BootBox' )
      var m = $( '#messagedialog' );
      // var ln=$('#messagedialoglnk');
      if ( m.length != 0 ) m.remove();
      m = $( "<div />", {
        'id': 'messagedialog',
        'data-role': 'popup',
        'data-history': 'false',
        'class': 'ui-content',
        'style': 'max-width:300px; min-width:120px',
        'data-history': 'false',
        'data-transition': 'flow',
      } )
      // $('<a />', {'href':'#', 'data-role':'button', 'data-rel':'back',
      //                 'data-theme':'b', 'data-icon':'delete',
      //                 'data-iconpos':'notext', 'class':'ui-btn-left'}).html('Close').appendTo(m);
      $( '<p />', {
          'id': 'innermessagedialog'
        } )
        .appendTo( m );
      // var l=$('<a />').attr('href','#').attr('id','buttondialog').jqmData('role','button').jqmData('rel', 'back')
      //    .jqmData('mini', true).jqmData('inline', true).css('padding', '10px').val('ok');
      // s.append( l );
      $.mobile.activePage.append( m );
      // $('#buttondialog').button();
      // m=$('#messagedialog');
      $( '#innermessagedialog' )
        .html( msgtext );
      m.popup( {
        history: false
      } );
      if ( typeof afterclose == 'function' ) m.bind( 'popupafterclose', afterclose );
      m.bind( 'popupafterclose', function() {
        m.remove();
      } );
      m.popup( 'open' );
    },
    getMessage: function( code ) {
      var ms = '';
      if ( screenLanguage === null ) return '';
      else {
        if ( screenLanguage == '' ) screenLanguage = 'en'
        ms = msg[ screenLanguage ][ code.toUpperCase() ];
        if ( ms == undefined ) console.log( screenLanguage + ':missing ' + code.toUpperCase() )
        else return ms
      }
    },
    translate: function() {
      console.log( 'translate: cette function utilise toujours JQM; ' );
      var that = this;
      $( '[data-ogtrans]' )
        .each( function() {
          var code = $( this )
            .data( 'ogtrans' );
          if ( typeof code != 'undefined' ) {
            // if (this.nodeName == 'A')
            // {
            // trick below works if span.ui-btn-text found otherwise straight stuff
            if ( $( this )
              .find( 'span.ui-btn-text' )
              .length != 0 ) $( this )
              .find( 'span.ui-btn-text' )
              .html( that.getMessage( code.toUpperCase() ) );
            //}
            else // BUTTON/LABEL/P/H1/OPTION/SPAN
              $( this )
              .html( that.getMessage( code.toUpperCase() ) );
          }
        } );
    },
    isValidDate: function( s, fmt ) {
      console.log( 'translate: cette function devrait utiliser moment.js ; ' );
      if ( s.length != 10 ) return false;
      if ( typeof fmt == 'undefined' ) fmt = 'fr';
      var bits = new Array();
      switch ( fmt ) {
        case 'fr':
        case 'en':
          bits = s.split( '/' );
          break
        case 'de':
          bits = s.split( '.' );
          break
        case 'es':
          bits = s.split( '-' );
          break
        default:
          alert( 'isValidDate : Date format split not implemented' )
      }
      if ( bits.length != 3 ) return false;
      if ( ( isNaN( Number( bits[ 0 ] ) ) ) || ( isNaN( Number( bits[ 1 ] ) ) ) || ( isNaN( Number( bits[ 2 ] ) ) ) ) return false;
      var d;
      switch ( fmt ) {
        case 'fr':
        case 'en':
        case 'es':
        case 'de':
          d = new Date( bits[ 2 ], bits[ 1 ] - 1, bits[ 0 ] );
          if ( ( d.getMonth() + 1 ) != Number( bits[ 1 ] ) || d.getDate() != Number( bits[ 0 ] ) || ( d.getFullyear() != Number( bits[ 2 ] ) ) ) return false;
          break;
        default:
          alert( 'isValidDate : Date format check not implemented' )
      }
      return d;
    },
    append: function( to, what ) {
      if ( to.length == 0 ) to = what;
      else to.append( what )
      return to;
    },
  }
  // return
} )();

;
( function( $, window, document, undefined ) {
  "use strict";
  $.ogcalendarLinear = function( element, options ) {
    var
      currentElement = element,
      plugin = this,
      defaults = {
        mode: 'month',
        locale: 'fr',
        currentDate: '',
        onDisplay: function() {},
        CurrentProductId: -1
      };
    plugin.settings = {};
    plugin.$element = $( element );
    plugin.element = element;

    var init = function() {
      var i;
      console.log( 'this is calendarLinear.init' );
      var html = '',
        dn;
      if ( moment === undefined ) {
        console.log( 'ogcalendar needs moment to be loaded' );
        return;
      }
      plugin.settings = $.extend( {}, defaults, options );
      moment.locale( plugin.settings.locale );
      html = '<div class ="ogcalendarLinear">' +
        '<div class="ogcalendar_onemonth">' +
        '<div class="ogcalendar_title">' +
        '   <div class="fa fa-angle-double-left  ogcalendar_title_before" style="height:27.8px"></div>' +
        '   <div class="ogcalendar_title_month" style="width:60% !important"> Décembre 2017</div>  ' +
        '   <div class="fa fa-angle-double-right ogcalendar_title_after" style="height:27.8px"></div> ' +
        '</div><div class="ogcalendar_body"></div></div></div>'
      dn = moment.localeData();
      html += "</div></div></div>";
      plugin.$element.html( html );
      // Attach events
      $( '.ogcalendar_title_before, .ogcalendar_title_after', plugin.$element ).on( 'click.ogeane', function( e ) {
        e.preventDefault();
        plugin.showCalendar( $( this ).data( 'month' ) )
      } );
      if ( plugin.settings.currentDate === '' )
        plugin.settings.currentDate = moment().format( 'YYYY-MM-DD' );
      plugin.showCalendar( plugin.settings.currentDate );
    }

    plugin.id = function( val ) {
      if ( val === undefined )
        return plugin.settings.CurrentProductId;
      else {
        plugin.settings.CurrentProductId = val;
        plugin.showCalendar();
      }
    }

    plugin.showCalendar = function( thisMonth ) {
      var plugin = this,
        currentDate = thisMonth == undefined ? moment() : moment( thisMonth ),
        calTitle = currentDate.format( 'MMMM YYYY' ),
        lastDay = currentDate.daysInMonth(),
        $ulelt = $( 'div.ogcalendar ul', plugin.$element ),
        html = '',
        i = 0;
      plugin.settings.currentDate = currentDate;
      $ulelt.css( 'display', 'none' )
      //cleanup
      $( '.ogcalendar_title_month', plugin.$element ).text( calTitle );
      $( '.ogcalendar_title_before', plugin.$element ).data( 'month', moment( currentDate ).add( -1, 'month' ).format( 'YYYY-MM-DD' ) );
      $( '.ogcalendar_title_after', plugin.$element ).data( 'month', moment( currentDate ).add( 1, 'month' ).format( 'YYYY-MM-DD' ) );
      html = '<ul class="ogcalendarLinear"><li class="lineid">&nbsp;</li>'
      for ( i = 1; i <= lastDay; i++ ) {
        html += '<li class="jour jourheader"><span class="nojour">' + i + '</span></li>';
      }
      $( '.ogcalendar_body', plugin.$element ).html( html + "</ul>" );
      getServerData( currentDate );
      $ulelt.css( 'display', 'block' )
    };

    /*
     * @param thisMonth is a moment object
     */
    var getServerData = function( thisMonth ) {

      $.ajax( {
          type: "post",
          global: false,
          context: plugin,
          url: glb.mainurl,
          data: {
            'ogm': "hardware",
            'ogs': 'getallbookings',
            'linkvalue': plugin.settings.CurrentProductId,
            'period': thisMonth.format( 'YYYY-MM-DD' )
          }
        } )
        .done( function( jsonData, status, jqxHr ) {
          this.jsonData = jsonData;
          var self = this,
            m = self.$element.data( 'month' ),
            newClass = '',
            countday = 1,
            maxdays = thisMonth.daysInMonth(),
            reg = new RegExp( /\d+/, 'g' );

          jsonData.forEach( function( line ) {
            var htmlline = [],
              free = '<li class="jour ogcalendar_free" >&nbsp;</li>';
            htmlline.push( '<ul class="ogcalendarLinear"><li class="lineid">' + line[ 0 ].reference + '</li>' );

            for ( countday = 1; countday <= maxdays; countday++ ) {
              htmlline.push( free );
            }
            line.forEach( function( day ) {

              var d = moment( day.calendarday ),
                nday = d.date(),
                match = '', title='',
              newClass = '';
              // if ( day.status == 0 ) newClass = "ogcalendar_free";
              if ( day.status == 1 ) newClass = "ogcalendar_waiting";
              if ( day.status == 2 ) newClass = "ogcalendar_booked";
              if ( day.status == 3 ) newClass = "ogcalendar_repair";
              if ( day.request_id != -1 ) {
                if ( htmlline[ nday ] != free ) {
                  title = day.request_no + ' ' + (day.company_name || '')
                  match = htmlline[ nday ].match( reg );

                  match.push( day.request_id )
                  htmlline[ nday ] = '<li class="jour ' + newClass + '" data-requestid="' +
                    match.join( ';' ) + '" title="' + title+ '">+</li>';
                } else {
                  title = day.request_no + ' ' + (day.company_name || '')
                  htmlline[ nday ] = '<li class="jour ' + newClass + '" data-requestid="' +
                    day.request_id + '" title="' + title+ '" >&nbsp;</li>';
                }
              } else
                htmlline[ nday ] = '<li class="jour ' + newClass + '" data-requestid="' +
                    day.request_id + '" >&nbsp;</li>';
            } )
            htmlline.push( '</ul>' );
            $( '.ogcalendar_body', plugin.$element ).append( htmlline.join( '' ) );
          } )
        } ) // Ajax.done

        .fail ( function (jqXHR, textStatus, errorThrown) {
           handleAjaxErrors ( jqXHR )
        });
    } // getServerData
    // Initialise the plugin
    init();
    return plugin;
  }
} )( jQuery, window, document );

/**
  * form Handler
  * * save to Local Storage
  * * retrieve from local storage
  * * display from list of fields
  * * build form from object description
  * * reset
  * v 1.00
  *
  * usage
  * fsave= new formHandle(<form id>); one per form to handle
  *
    fsave.list([array of field names to list])
      retuns a list formatted as follows: id#field 1 content&nbsp;field 2 content&nbsp;....
      meant to be used to display a select list to the user.
  * fsave.save() - will create or update depending whether form has data-ogformsaveid
  * fsave.restore(id in LocalStorage) - redisplay the form on screen and populates data-ogformsaveid
  * fsave.remove(id in LocalStorage) if id = -1 the attemps to use data-ogformsaveid

  */

/* the following routine was found on StackOverflow.com Tobias Cohen 27/07/2009 */
/* Jquery must have been loaded prior to this */




if (typeof $.fn.serializeObject == 'undefined') {
$.fn.serializeObject = function()
{
   var o = {};
   var a = this.serializeArray();
	 $('input:checkbox', this).not(':checked').map(function() {
   a.push( { name: this.name, value: "false" });
 });
   $.each(a, function() {
       if (o[this.name]) {
           if (!o[this.name].push) {
               o[this.name] = [o[this.name]];
           }
           o[this.name].push(this.value || '');
       } else {
           o[this.name] = this.value || '';
       }
   });
   return o;
};
}

function browserStorage (prefix, mode) {
  if (typeof prefix == 'undefined')
      prefix = '';
  mode = mode || 'local';
    if (mode != 'local')
   mode ='session';
  this.mode = mode;
  this.prefix = prefix;
  return this;
}

    browserStorage.prototype.isAvailable = function() {
        // if ( typeof $.jStorage === "object" ) {
        //     return true;
        // }
        try {
          if (this.mode == 'local')
            return localStorage.getItem;
          else
            return sessionStorage.getItem;

        } catch ( e ) {
            return false;
        }
    };

  browserStorage.prototype.set = function( key, value ) {
            try {
              if (this.mode == 'local')
                localStorage.setItem( this.prefix+key, value + "" );
              else
                sessionStorage.setItem( this.prefix+key, value + "" );
            } catch (e) {
                //QUOTA_EXCEEDED_ERR
            }
        // }
    };

    browserStorage.prototype.get = function( key ) {
      if (this.mode == 'local')
            return localStorage.getItem( this.prefix+key );
      else
          return sessionStorage.getItem( this.prefix+key );

    };

    browserStorage.prototype.setKey = function( key, value ) {
            try {
              if (this.mode == 'local')
                localStorage.setItem( key, value + "" );
              else
                sessionStorage.setItem( key, value + "" );
            } catch (e) {
                //QUOTA_EXCEEDED_ERR
            }
        // }
    };

    browserStorage.prototype.getKey = function( key ) {
      if (this.mode == 'local')
            return localStorage.getItem( key );
      else
          return sessionStorage.getItem( key );

    };

    browserStorage.prototype.remove = function( key ) {
      if (this.mode == 'local')
            localStorage.removeItem( this.prefix+key );
        else
            sessionStorage.removeItem( this.prefix+key );
    };

    browserStorage.prototype.removeKey = function( key ) {
      if (this.mode == 'local')
            localStorage.removeItem( key );
        else
            sessionStorage.removeItem( key );
    };

    browserStorage.prototype.clear = function( key ) {
      if (this.mode == 'local')
            localStorage.removeItem( this.prefix+key );
      else
          sessionStorage.removeItem( this.prefix+key );
    };


    /*
     *
     * filter is an optional array of objects
     *     fieldname : name of field
     *     value : value to check against
     *     compfn : comparison mode one of '=', '>', '>=', '<', '<=', '!=', '*'
                  defaults to '='
     *     comptype : String (S), Numerical (N), Integer (I); defaults to string
     *     Note Date & Boolean not yet implemented
     */
    browserStorage.prototype.list = function (prefix, fieldlist, afilter) {
      var result = [];
      var fieldindex=[];
      prefix = this.prefix+prefix;
      for (var i=0, len=localStorage.length; i<len; i++) {
        var key = localStorage.key(i);
        if (key.indexOf(prefix) === 0) { /* prefix found and @ start of key */
          var line = {};
          var data = JSON.parse(localStorage[key]);
          var accepted=true;
          if (typeof afilter !== 'undefined')
          {
              $.each(afilter, function (j, obj) {
                  var fieldname = obj.fieldname.trim(),
                      value, vardata,
                      comptype = (typeof obj.comptype != 'undefined') ? obj.comptype.toUpperCase().trim() : 'S',
                      compfn = (typeof obj.comp != 'undefined') ? obj.comp.toUpperCase().trim() : '=';
                  switch (comptype) {
                  case 'N':
                    value = parseFloat(obj.value, 10);
                    vardata = parseFloat(data[fieldname]);
                    break;
                  case 'I':
                    value = parseInt(obj.value, 10);
                    vardata = parseInt(data[fieldname], 10);
                    break;
                  default: // string equivalent
                    value = String(obj.value).trim().toUpperCase();
                    vardata = String(data[fieldname]).trim().toUpperCase();
                  }

                  switch (compfn) {
                    case '=':  if (value != vardata)
                        {accepted = false; return false;  }
                      break;
                    case '!=': if (value == vardata)
                        { accepted = false; return false;}
                      break;
                    case '>': if (value <= vardata)
                      { accepted = false; return false;}
                      break;
                    case '<': if (value >= vardata)
                      { accepted = false; return false; }
                      break;
                    case '>=': if (value < vardata)
                      { accepted = false; return false; }
                      break;
                    case '<=': if (value > vardata)
                      { accepted = false; return false; }
                      break;
                    case '*': if ((comptype == 'S') && (vardata.indexOf(value) == -1))
                      { accepted = false; return false; }
                      break;
                    default:
                      accepted = false; return false;
                  }
                });
            }
          if (! accepted) continue;
          line._localid = key.substring(key.indexOf('~')+1);

          for (var j=0; j < fieldlist.length; j++)
             line[fieldlist[j]] = data[fieldlist[j]];
          result.push(line);
          }
        }
        return result;
    }; //---- end of list


browserStorage.prototype.listKey = function (prefix, fieldlist, afilter) {
      var result = [];
      var fieldindex=[];
      prefix = this.prefix+prefix;
      for (var i=0, len=localStorage.length; i<len; i++) {
        var key = localStorage.key(i);
        if (key.indexOf(prefix) === 0) { /* prefix found and @ start of key */
          var line = {};
          var data = JSON.parse(localStorage[key]);
          var accepted=true;
          if (typeof afilter !== 'undefined')
          {
              $.each(afilter, function (j, obj) {
                  var fieldname = obj.fieldname.trim(),
                      value, vardata,
                      comptype = (typeof obj.comptype != 'undefined') ? obj.comptype.toUpperCase().trim() : 'S',
                      compfn = (typeof obj.comp != 'undefined') ? obj.comp.toUpperCase().trim() : '=';
                  switch (comptype) {
                  case 'N':
                    value = parseFloat(obj.value, 10);
                    vardata = parseFloat(data[fieldname]);
                    break;
                  case 'I':
                    value = parseInt(obj.value, 10);
                    vardata = parseInt(data[fieldname], 10);
                    break;
                  default: // string equivalent
                    value = String(obj.value).trim().toUpperCase();
                    vardata = String(data[fieldname]).trim().toUpperCase();
                  }

                  switch (compfn) {
                    case '=':  if (value != vardata)
                        {accepted = false; return false;  }
                      break;
                    case '!=': if (value == vardata)
                        { accepted = false; return false;}
                      break;
                    case '>': if (value <= vardata)
                      { accepted = false; return false;}
                      break;
                    case '<': if (value >= vardata)
                      { accepted = false; return false; }
                      break;
                    case '>=': if (value < vardata)
                      { accepted = false; return false; }
                      break;
                    case '<=': if (value > vardata)
                      { accepted = false; return false; }
                      break;
                    case '*': if ((comptype == 'S') && (vardata.indexOf(value) == -1))
                      { accepted = false; return false; }
                      break;
                    default:
                      accepted = false; return false;
                  }
                });
            }
          if (! accepted) continue;
          line._localid = key;

          for (var j=0; j < fieldlist.length; j++)
             line[fieldlist[j]] = data[fieldlist[j]];
          result.push(line);
          }
        }
        return result;
    }; //---- end of list


    browserStorage.prototype.uniqueid = function (prefix)
    {
      prefix = prefix ? this.prefix+prefix : this.prefix;
      var id=0;
      for (var i=0, len=localStorage.length; i<len; i++) {
        var key = localStorage.key(i);
        if (key.indexOf(prefix) === 0) { /* prefix found and @ start of key */
          var currentid = key.substring (key.indexOf('~') + 1);
          id = (parseInt(currentid,10) > id) ? parseInt(currentid,10) : id;
      }
     }
     return parseInt(id, 10)+1;
    };

    browserStorage.prototype.uniqueidKey = function (prefix)
    {

      var id=this.uniqueid (prefix);
      prefix = prefix ? this.prefix+prefix : this.prefix;
      return ''.concat(prefix,'~', id)

    };



/** ***********************************************************
  * Creation entry point. the formid is prefixed by '#'
  *
  * url is rarely used, an might be deprecated
  *
  **/
function formHandler (formid, url) {
  this.store = new browserStorage();
  if (formid.substring(0,1) != '#')
    formid = '#'+formid;

  this.formid = formid;
  this.$form = $(formid);
  this.parentPage = this.$form.parents('[data-role="page"]');
  this.storeprefix = formid.substring(1) + '~';
  if (typeof url  !== 'undefined')
    this.url = url;
  return this;

}
/**
  *
  * save the form content using the <id for the form>~<id>.
  * if id is not provided - look at form data-saveid if not present generate a unique id.

  * @param  formid id of the form to handle
  * @param  id: unique identifier
  * returns id of the newly saved form
  */


formHandler.prototype.getid=function (id, createnew)
  {
  if (createnew === undefined)  createnew=true;
  if (typeof id !== 'undefined')
     return id;
  id = this.$form.data('ogformsaveid');
  if (typeof id !== 'undefined')
      return id;
  if (createnew)
    return this.store.uniqueid(this.storeprefix);
  else
    return null;
  };

formHandler.prototype.newid=function (id, createnew)
  {
    return this.store.uniqueid(this.storeprefix);
  };




/** *************************************************************
  * save the form data into local storage
  * using the provided id
  * prefixed by storeprefixid
  **/

formHandler.prototype.save = function (id)
  {
  this.$form = $(this.formid);
  id = this.getid(id);

  var frmdata = this.$form.serializeObject();
  var data=JSON.stringify(frmdata);
  this.store.set (this.storeprefix+id, data);
  this.$form.data('ogformsaveid', id);
  return true;
};


formHandler.prototype.resetForm = function(jqPage, forminfo) {
var jqForm = $('form', jqPage);
$(':input', jqForm).not('select').val('');
$(':radio', jqForm).checkboxradio('refresh');
$(':checkbox', jqForm).attr('checked', false).checkboxradio('refresh');
$('select.ui-slider-switch', jqForm).val(0).slider('refresh');
$('select', jqForm).not('.ui-slider-switch').prop('selectedIndex', 0).trigger('change');
$('[data-type="range"]', jqForm).val(0).trigger('change');

// reset values to default values

if (forminfo.defaults) {
  $.each (forminfo.defaults, function (k,v) {

    switch (v.type) {
      case 'select' : $('#'+forminfo.id+k, jqForm).val(v.value).selectmenu('refresh');
        break;
      default:
        $('#'+k, jqForm).val(v.value);
      }
    });
  }
};



/** *************************************************************
  * refresh (in JQM terms) form elements on screen
  * pageid always to restrict the refresh to certain div with data-role="page"
  * in fact all form elements belonging to the same container
  */

formHandler.prototype.refresh = function(pageid)
{
this.$form = $(this.formid);
if (typeof pageid != 'undefined')
{
  if (pageid.substring(0,1) != '#')
    pageid = '#' + pageid;
  if ($(pageid).length === 0)
    alert('Sorry '+pageid+' does not exists');
//$(':radio, :checkbox', pageid).filter(this.filterScreen(pageid)).checkboxradio('refresh')

$(':radio, :checkbox', pageid).checkboxradio().checkboxradio('refresh');
$('select.ui-slider-switch', pageid).slider("refresh");
$('select', pageid).not('.ui-slider-switch').selectmenu().selectmenu('refresh');
// $('[data-type="range"]', pageid).slider('refresh');
$('[data-type="range"]', pageid).slider().slider('refresh');
}
else
{
$(':radio, :checkbox', this.$form).checkboxradio().checkboxradio('refresh');
$('select.ui-slider-switch', this.$form).slider().slider('refresh');
$('select', this.$form).not('.ui-slider-switch').selectmenu().selectmenu('refresh');
$('[data-type="range"]').slider().slider('refresh');
}
};

/** *************************************************************
  *  id is the internal id
  *  returns the values as an object
  **/
formHandler.prototype.getdata = function (id)
  {
  var data=this.store.get (this.storeprefix+id);
  if ((data === undefined) || (data === null))
    return false;
  return JSON.parse(data);
  };

/** *************************************************************
  *  id is the internal id
  *  values is an object where each propertie is a field
  **/
formHandler.prototype.setdata = function (id, values)
  {
  var data=JSON.stringify(values);
  this.store.set (this.storeprefix+id, data);
  return true;
  };

/** *************************************************************
  *  id is the internal id (ie storageid, not db PK)
  *  newvalues is an object where each propertie is a field
  *    each element of newvalues will be updated and saved back to the local storage
  **/
formHandler.prototype.changedata = function (id, newvalues)
  {
  var values = this.getdata(id);
  if (values) {
    $.each(newvalues, function (key, newvalue) {
      if (values[key])
        values[key] = newvalue;
    });
    this.setdata(id, values);
    }
  };


/** *************************************************************
  * populate the form with data array of data
  * fieldlist : is a limiter of fields, if undefined then all fields in data will be displayed
  * id pk of the record to be saved in the form itself
  **/
formHandler.prototype.showdata = function (data, fieldlist, id)
{
var field;

if (typeof data == 'string')
  data=JSON.parse(data);

$.each(data, function(key, value) {

  if (fieldlist.length !== 0)
     {
       if (fieldlist.indexOf(key) == -1)
         return true; // continue equiv for $.each
     }
  field=$("[name='" + key +"']", this.$form);
  if (field.length === 0)
      return true; // continue equiv for $.each

  if (field.is(':radio'))
  { // need to cover all the basics;
    field.removeProp("checked").attr("checked", "").removeAttr("checked"); //.checkboxradio('refresh');;
    $.each(field, function (i, f) {
      if ( ( (value instanceof Array)  && ( value.indexOf(f.value) != -1 )) ||  (f.value == value))
        // $(f).prop("checked", true).attr("checked", "checked"); // .checkboxradio('refresh');;
        $(f).prop("checked", true); // .checkboxradio('refresh');;

    });

  }
  else if ( field.is(':checkbox'))
  {
    field.removeProp("checked").attr("checked", "").removeAttr("checked"); // .checkboxradio('refresh');
    $.each(field, function (i, f) {
      if ( ( (value instanceof Array)  && ( value.indexOf(f.value) != -1 )) ||  (f.value == value))
        // $(f).prop("checked", true).attr("checked", "checked"); // .checkboxradio('refresh');
      $(f).prop("checked", true); // .checkboxradio('refresh');

    });
  }
  else
  {
    field.val(value);
  }
});

this.$form.data('ogformsaveid', id);
};

/** *************************************************************
  * show an object containing values on screen fields
  * with the possibility to limit the fields displayed using fieldlist
  * the primary key is assumed to be in data.id
  **/
formHandler.prototype.display = function (data, fieldlist)
{
   if (typeof fieldlist ==  'undefined')
      fieldlist = [];
   this.$form = $(this.formid);

   this.showdata (data, fieldlist,data.id );
   this.refresh(this.formid);
};

/** ***********************************************************
  * get a record from storage defined by it's id (internal storage id)
  * and populates the form
  * with the possibility to limit the fields displayed using fieldlist
  *
**/
formHandler.prototype.restore = function (id, fieldlist )
  {
  if (typeof fieldlist ==  'undefined')
    fieldlist = [];

  this.$form = $(this.formid);
  id = this.getid(id, false);
  var data=this.store.get (this.storeprefix+id);
  if ((data === undefined) || (data === null))
    return false;
  //data = JSON.parse(data);
  // var fieldsToProtect = this.$form.find( ":input" ).not( ":submit" ).not( ":reset" ).not( ":button" ).not( ":file" );
  // var frmdata = this.$form.serializeArray();
   this.showdata (data, fieldlist, id);
  return true;
};


/**
 **
 **/
formHandler.prototype.validate = function () {

  var v, r, rules = this.validationrules; errorCount = 0;
  $ ('label.error', this.formid).remove();
  for (v in rules) {
    if ( rules[v].indexOf('required') >= 0 ) {
      var elt = $(this.formid+v);
      if ( elt.val().replace(/^\s+|\s+$/g,'') == '') {// trimmed
        elt.parent().append( $ ('<label for="'+elt.attr('id')+' " class="error">This field is required</label>  '));
        errorCount ++;
      }
      }
    }
  if (errorCount == 0)
     return true
  else
     return errorCount
}


/** *************************************************************
 *  returns a list of record from storage
 *  with an optional filter function to decide what to display
 *  only records from the current form will be displayed (using
 *  the storeprefix which is appended to the local id)
**/
formHandler.prototype.list = function (fieldlist, filter)
{
 return this.store.list(this.storeprefix, fieldlist, filter );
};

/** *************************************************************
  * removes a record from the local storage
  * the id passed is the id in the local storage not the PK of the record
  * can only delete records from the current form object
  * any other stored data is left alone
  **/
formHandler.prototype.remove = function (id)
  {
   id = this.getid(id, false);
   if (id === null) return false;
   this.store.remove(this.storeprefix+id);
   return true;
  };


/** ***********************************************************
  * send the form data directly to the server
  * uses a temporary storage to do so.
  **/

formHandler.prototype.sendFormData = function() {

  this.save ('tempid');
  this.$form = $(this.formid);
  var ogm = this.$form.jqmData('ogm'),
      ogs = this.$form.jqmData('ogs'),
      ogt = this.$form.jqmData('ogt'),
      pkid = this.$form.jqmData('ogpkid'),
      p = {};

  if (typeof ogt=='undefined') ogt='0';
  p={'ogm':ogm, 'ogs':ogs, 'ogt':ogt, 'ogaction':'put', 'oglinkvalue':pkid };

  if (this.sendData('tempid',p ) == 200)
  {
    this.remove('tempid');
    return true;
  }
  else
    return false;
};

/** *************************************************
  Delete the current record from the server
  **/
formHandler.prototype.delete = function() {

  this.save ('tempid');
  this.$form = $(this.formid);
  var ogm = this.$form.jqmData('ogm'),
      ogs = this.$form.jqmData('ogs'),
      ogt = this.$form.jqmData('ogt'),
      pkid = this.$form.jqmData('ogpkid'),
      p = {};

  if (typeof ogt=='undefined') ogt='0';
    p={'ogm':ogm, 'ogs':ogs, 'ogt':ogt, 'ogaction':'remove', 'oglinkvalue':pkid };

  if (this.sendData('tempid',p ) == 200)
  {
    this.remove('tempid');
    return true;
  }
  else
    return false;
};



/** ******************************************************
  * send the data stored in local storage to the server
  * along with some data parameters
  * the lstore param allows to get userid and usercheck to
  * allows some level of control on the validity of the data
  * the local ids are transfered via ID which can be an array or a single record
  **/

formHandler.prototype.sendData = function (id, params, lstore)
  {
  var rid = [], that = this, result=false,  datasend=[];

  if ( ! $.isArray (id) )
      rid.push(id);
  else
      rid = id;

  $.each(rid, function (i,lid)
  {
  var data=that.store.get (that.storeprefix+lid, data);
  if (typeof data != 'undefined')
    datasend.push(JSON.parse(data));
  });

  datasend=JSON.stringify(datasend);

  if (typeof lstore != 'undefined')
  {
    // add user & pass
    var u = lstore.get('userid');
    var c = lstore.get('usercheck');
    params= $.extend (params, {ogu:u, ogc:c, formdata:datasend});
  }
  else
    params= $.extend (params, {formdata:datasend});



  /** note this must be a synchronous call (blocking) as we are expecting a return value ***/
  /** todo: this should be changed to a promise .... **/
  $.ajax({
    async: false,
    type:"POST",
    url: "/index.php",
    data: params,
    datatype:'json',
    error: function ( jqXHR,  textStatus, errorThrown ) {
      result = jqXHR.status;
    },
    success: function () {
      result = 200;
    }

  });
  return result;
  };


/** *************************************************************
  * embeds a js string send via json as an event handler
  * into a try ... except block to ensure smooth execution
  * w/o failure.. Part of the form generation module
  * change: the function is just checked, it will only be attached called in real time
  * because e is not available @ attachement time!!!!
  **/

  formHandler.prototype.checkEventHandler = function   (eventname, oscript)
    {
      // decorate script with a runtime try/catch + err reporting
       oscript= "try { " + oscript + " } catch (error) { " +
         " var txt_error = 'Firing ' + e.type + ' on ' +  $(e.target).attr('id') + ' error ' + error.message;  " +
         " console.log(txt_error); alert(txt_error) }";

       var fnarray = [ oscript] ;
       try {
            var fn= Function.apply(null, fnarray);
            return oscript;
           }
       catch (error) {
        var txt_error = 'Attaching ' + eventname + ' error ' + error.message + ' ... see console';
        console.log(txt_error);
        console.log (oscript);
        alert(txt_error);
        return false;

       }
    };
/** *************************************************************
  * build one field
  * Part of the form generation module
  **/

  formHandler.prototype.makefield = function  (fieldinfo, options)
  {
   options= options || {};
   var //pr = this.namespace.toLowerCase(),
       id = (fieldinfo.id || fieldinfo.name || '').toLowerCase(),
       e , evscript, t='', cl='', d='', i, l, lcl='',
       required, label, readonly;
    id = this.formid.slice(1) + id;

    lcl = fieldinfo.labelclass || '';

   required = options.required || false; // validation rules are set in options
   readonly = fieldinfo.readonly || false;
   cl = fieldinfo.class || '';

   if (required) { cl += ' ogrequired'; lcl += 'oglabrequired';}
   if (readonly) { required = false; cl += ' ogreadonly';}


   if ( (options.divwrap === true) || (fieldinfo.divwrap === true)) {
       d=$('<div />', {'data-role': 'fieldcontain'});
     }
   else if (typeof options.divwrap == 'string')
       d=$(options.divwrap);

    if (typeof fieldinfo.fieldtype == 'undefined')
       {
       d = $('<div />').html('fieldtype not found<br/>');
       return d;
     }
    t=fieldinfo.fieldtype.toLowerCase ();
    switch (t) {

      case 'text':
      case 'email':
      case 'number':
      case 'date':
        label = fieldinfo.label || '';
        if (label) {
           e=$('<label />', {'for':id, 'class':lcl }).text(fieldinfo.label);
           if (d.length === 0) d = e; else e.appendTo(d) ;
           }
        e=$('<input />', {'type':t, 'name':fieldinfo.name, 'class': cl, 'id':id});
        if ( readonly)
          e.attr('readonly', 'readonly');
        if (( options.default ) && (typeof fieldinfo.default != 'undefined'))
          e.val(fieldinfo.default);
        if (d.length === 0) d = e; else  e.appendTo(d) ;
        break;
      // case 'paintbox':
      //    fieldinfo.width = fieldinfo.width || '100%';
      //    fieldinfo.height = fieldinfo.height || '300px';
      //    d=$('<canvas />', {'id':fieldinfo.name}). attr('width', fieldinfo.width).attr('height',  fieldinfo.height);
      //    break;
      case 'rawtext':
        d=$('<div />').html(fieldinfo.label);
        break;
      case 'break':
        d=$('<div />').html('<br />');
        break;

      case 'canvas':
      label = fieldinfo.label || '';
        if (label) {
            d=$('<label />', {'for':id, 'class':lcl }).text(fieldinfo.label);
            }
         fieldinfo.width = fieldinfo.width || '300px';
         fieldinfo.height = fieldinfo.height || '300px';
         e=$('<canvas />', {'id':fieldinfo.name}). attr('width', fieldinfo.width).attr('height',  fieldinfo.height);
         e.css('border', '1px solid black');
          if (fieldinfo.click) {
             evscript = this.checkEventHandler ('vclick', fieldinfo.click);
             if (evscript)
               e.on('vclick', function (event) {
                  try {
                    event.preventDefault();
                    var fn= new Function (fieldinfo.click);
                    fn ();
                    } catch (error) {
                    var txt_error = 'Firing ' + event.type + ' on ' +  $(event.target).attr('id') + ' error ' + error.message;
                    window.console.log(txt_error); window.alert(txt_error);
                    }
               });
           }
         if (d.length === 0) d = e ; else  e.appendTo(d) ;
         break;
      case 'file':
         //d = $('<label />', {'for':id, 'class':lcl }).text('Click here to take a picture');
         d=$('<input />', {'type':'file', 'id':fieldinfo.name, 'name':fieldinfo.name});

         if (fieldinfo.accept)
           d.attr ('accept', fieldinfo.accept);
         if (fieldinfo.onchange) {
            d.on ('change', {'fieldname': fieldinfo.name}, function (e) {
                e.preventDefault();
                var fn = new Function (fieldinfo.onchange);
                fn(e.data.fieldname);
               });
          }

         break;
      case 'range':
         label = fieldinfo.label || '';
        if (label) {
           e=$('<label />', {'for':id, 'class':lcl}).text(fieldinfo.label);
           if (d.length === 0) d = e; else e.appendTo(d) ;
           }

        e=$('<input />', {'type':'range', 'name':fieldinfo.name, 'class': cl, 'id':id, 'min':fieldinfo.min, 'max':fieldinfo.max});
        if ( readonly)
          e.attr('readonly', 'readonly');
        if (( options.default ) && (typeof fieldinfo.default != 'undefined'))
          e.val(fieldinfo.default);
        if (d.length === 0) d = e; else  e.appendTo(d) ;
        break;
      case 'hidden':
        d=$('<input />', {'type':'hidden', 'name':fieldinfo.name, 'id':id});
        break;
      case 'check':
        d=$('<label>').text(fieldinfo.label);
        e=$('<input />', {'type':'checkbox', 'data-theme':fieldinfo.theme || 'b', 'name':fieldinfo.name, 'id':id});
        e.appendTo(d);
        break;

      case "textarea":
        label = fieldinfo.label || '';
        if (label) {
            e=$('<label />', {'for':id, 'class':lcl }).text(fieldinfo.label);
            if (d.length === 0) d = e ; else  e.appendTo(d) ;
            }

        e=$('<textarea />', {'name':fieldinfo.name, 'class': cl, 'id':id });
        if ( readonly)
          e.attr('readonly', 'readonly');
        if (( options.default ) && (typeof fieldinfo.default != 'undefined'))
          e.text(fieldinfo.default);

        if (d.length === 0) d = e; else e.appendTo(d) ;
        break;

      case 'button':
        if (options.buttonbar === true) {
          e=$('<a />', {href:'#', 'data-role':"button", 'data-theme':'b', 'data-inline': true, 'data-icon': fieldinfo.icon || 'none', 'id':fieldinfo.id });
          }
        else {
          e=$('<a />', {href:'#',  'data-role':"button", 'data-theme':'b', 'data-iconpos':"top", 'data-icon': fieldinfo.icon || 'none', 'id':fieldinfo.id });
         }
          // if (fieldinfo.disabled)
          //   e.buttonMarkup("disable");
          // else
          //   e.buttonMarkup();

          if (fieldinfo.click) {
             evscript = this.checkEventHandler ('click', fieldinfo.click);
             if (evscript)
               e.on('vclick', function (event) {
                  try {
                    event.preventDefault();
                    var fn= new Function (fieldinfo.click);
                    fn ();
                    } catch (error) {
                    var txt_error = 'Firing ' + event.type + ' on ' +  $(event.target).attr('id') + ' error ' + error.message;
                    window.console.log(txt_error); window.alert(txt_error);
                    }
               });
           }
          if (typeof fieldinfo.label != 'undefined')
             e.text(fieldinfo.label);
          if (d.length === 0) d = e; else e.appendTo(d) ;
      break;
      case 'flip':
        label = fieldinfo.label || '';
        if (label) {
            e=$('<label />', {'for':id, 'class':lcl }).text(fieldinfo.label);
            if (d.length === 0) d = e ; else  e.appendTo(d) ;
            }
        e=$('<select />', {'name':fieldinfo.name, 'class': cl, 'id':id, 'data-role': 'slider',
            'data-track-theme': fieldinfo.tracktheme ? fieldinfo.tracktheme : 'a',
            'data-theme': fieldinfo.theme ? fieldinfo.theme : 'a',
             });
        if (! fieldinfo.selectlist ) window.console.log (fieldinfo.name + ' selectlist not found');
        else {
        for (i=0; i<fieldinfo.selectlist.length; i++) {
          if ((options.default) && (fieldinfo.default) &&  (fieldinfo.default == fieldinfo.selectlist[i].value))
             e.append($('<option>', {'value': fieldinfo.selectlist[i].value, 'selected': 'selected'}).text(fieldinfo.selectlist[i].label));
          else
             e.append($('<option>', {'value': fieldinfo.selectlist[i].value}).text(fieldinfo.selectlist[i].label));
         }
        }
       if (d.length === 0) d = e; else e.appendTo(d) ;
        break;
      case 'select':
      label = fieldinfo.label || '';
        if (label) {
            e=$('<label />', {'for':id, 'class':lcl }).text(fieldinfo.label);
            if (d.length === 0) d = e; else e.appendTo(d) ;
            }
        e=$('<select />', {'name':fieldinfo.name, 'class': cl, 'id':id });
        if ( readonly)
          e.attr('readonly', 'readonly');
        if ( ! fieldinfo.selectlist)
            e.append($('<option>', {'value': -1}).text('Missing select list'));
        else
          for (i=0; i<fieldinfo.selectlist.length; i++) {
            if ((options.default) && (fieldinfo.default) &&  (fieldinfo.default == fieldinfo.selectlist[i].value))
              e.append($('<option>', {'value': fieldinfo.selectlist[i].value, 'selected': 'selected'}).text(fieldinfo.selectlist[i].label));
            else
              e.append($('<option>', {'value': fieldinfo.selectlist[i].value}).text(fieldinfo.selectlist[i].label));

          }
       if (d.length === 0) d = e; else e.appendTo(d) ;
       break;
      default:
        d.html("I don't know how to make a " + t + " field<br/>");
      }


     return d;
    };

 /*
  *
   sample usage
   buildform
    ('maindata', {
    name: 'myform',
    method: 'post',
    action: 'index.php',
    ogm: 'ogm=g&ogs=1',
    ogs:
    ogt:
    cols: 'a',
    theme: 'b',
    header: {
       label: 'Saisie des Clients',
     navbar: [ { 'fieldtype':'button', 'label':'Mickey', 'linkto': '', 'linkjs':'doalert("Bouton 1")'},
          { 'fieldtype':'button', 'label':'Mini', 'linkto': '', 'linkjs':'doalert("Bouton 2")'}
        ] },
  body: [
   { 'fieldtype':'text', col:'a','label':'Nom', 'name': 'ClientName'},
   { 'fieldtype':'text', col:'a', 'label':'Prenom', 'name': 'prenom'},
   { 'fieldtype':'textarea', col:'b', 'label':'adresse', 'name': 'adresse'},
   { 'fieldtype':'text', col:'b','label':'Code Postal', 'name': 'cp'},
   { 'fieldtype':'text', col:'b','label':'ville', 'name': 'ville'},
   { 'fieldtype':'container', 'type':'collapsible', 'id':'c1', 'Label':'Contact'},
   { 'fieldtype':'text', 'container':'c1',  col:'b','label':'Téléphone', 'name': 'tel'},
   { 'fieldtype':'text', 'container':'c1',  col:'b','label':'Email', 'name': 'email'},
   { 'fieldtype':'text', col:'b','label':'C.A. an -1', 'name': 'ca'}
   ],
   footer:{
     label: "C'est tout"
   }
});*/


/** *************************************************************
  * builds a JQM form on screen
  * With header/content/footer
  * using the syntasx shown above
  * Main entry point of the form generation module
  **/

 /** *************************************************************
  * builds a JQM form on screen
  * With header/content/footer
  * using the syntasx shown above
  * Main entry point of the form generation module
  **/

 formHandler.prototype.buildform = function (pagedest, forminfo, manualhtml)
  {
   this.forminfo = forminfo;
   if (pagedest.substring(0,1) == '#')
        pagedest = pagedest.substring(1);
   this.namespace = pagedest;
   var p=$('#' + pagedest),
       that = this,
       theme= forminfo.theme || 'a',
       c='', h='', n='', nsub='';
   if (p.length !== 0)
      {
      if ($('#'+forminfo.id,p).length != 0) {// this form has already been created
        this.resetForm($('#'+pagedest), forminfo);
        $.mobile.changePage($('#'+pagedest));
        return
      }
      p.remove();
      }

// $.mobile.loading('show');

    /** TODO optimize by saving p before appending it to the body
        and restoring it if it exists already
     **/

    p = $('<div />',{ 'data-role': 'page', 'data-theme':theme, 'id':pagedest });
    p.jqmData('ogform', forminfo);
    p.jqmData('formstatus', 'ready');

    // header can have a label, a navbar, a left icon or link and a right icon or link
    if (typeof forminfo.header != 'undefined')
    {
      c = $('<div />',{ 'data-role': 'header', 'data-theme':theme,
           'data-position': 'fixed', "id":this.namespace+'header' });

      if (typeof forminfo.label != 'undefined')
      {

        h=$('<div />', { 'style': 'display:block', 'class':"ui-bar"}).html ("<h1 class='apptitle'>" + forminfo.label + "</h1>");
        c.append(h);
      }
      if (forminfo.header.navbar)
      {
       n = $('<div  />', {'class':"ui-bar-a",'style': 'display:block'})
       //nsub=$('<ul />');
      $.each (forminfo.header.navbar, function ( index, value)
       {
          n.append(that.makefield(value, {buttonbar: true}));
       });
     // n.append(nsub);
      c.append(n);
      // n = $("<div />", {'class':'error'});
      // n.append($('<span />'));
      // c.append(n);
      }

    p.append(c);



    }

    /** build the body. The body contains the form
      * has the hability to handle columns and containers
      * even containers embedded inside columns
      **/
    if (typeof forminfo.body != 'undefined') {

      var container=[],
          cc=0,
          current={},
          cnt={},
          prev={},
          // details= [],
          // frmdet= '',
          i=0,
          // temp='',
          //incontainer=false,
          //padClass='',
          bodyversion = 1,
          functionlist=[];

          current.grid='';
          current.col='';
          current.ctn='';
          cnt.grid=0;
          cnt.col=0;
          cnt.ctn=0;
          cnt.cntid=[];
          prev.grid='';
          prev.col='';
          prev.ctn='';



      //if  (screen.width > 1024) padClass = "og-pad20"; else if (screen.width > 800) padClass="og-pad10";
      container.push($('<div />',{ 'data-role': 'content',  "class":"stdbackground", "id":this.namespace+'content', 'data-theme':theme}));
      container.push($('<form />',{ 'name': forminfo.name,
                             'id':  forminfo.id, // remove the '#'
                             'method' : forminfo.method || 'post',
                             'action' : forminfo.action || '#',
                             'data-ogm': forminfo.ogm||'x',
                             'data-ogs': forminfo.ogs||'x',
                             'data-ogt': forminfo.ogt|| 0
                             }));

      // var colmode = forminfo.cols || '';
      // if (colmode != '')
      //   container.push($('<div />', {'class':'ui-grid-'+colmode}));

      if (typeof forminfo.bodyversion != 'undefined')
         bodyversion = forminfo.bodyversion;


      cc = container.length - 1;
      if (bodyversion == 1) {
      $.each (forminfo.body, function ( index, value)
       {
         if (value.fieldtype.toLowerCase() == 'hidden') {
          container[cc].append(that.makefield(value, {'divwrap': false, 'prefix':forminfo.name}));
          return true; // moves to next in $.each
        }

          cnt.total = 0;
          current.grid = value.grid || '';
          current.col = value.col || '';
          current.ctn = value.container || '';
          // check if there are any closed item in order of appearance. (Grid/column/container)


          if (current.grid != prev.grid)
              {
              cnt.total = cnt.grid+ cnt.col // + cnt.ctn;
              // force a change of column & container if they existed
              prev.col = '';
              prev.grid = '';
              // prev.ctn = '';
              cnt.col  = 0;
              // cnt.ctn = 0;
              }
          else
            if (current.col != prev.col)
                {
                cnt.total = cnt.col//+ cnt.ctn;
                prev.ctn = '';
                cnt.ctn-- ;
                }
           // else
           if ((current.ctn != prev.ctn) && (value.container != prev.ctn)) // définition d'un sous container (eg. fieldset inside fieldset)
                cnt.total += cnt.ctn;

          for ( i=0; ( (i<cnt.total) && (cc > 1)) ; i++) {
                container[cc-1].append(container[cc]);
                container.pop();
                cc--;
              }



          if ((current.grid != prev.grid) && (current.grid != ''))
              {
              cc++;
              cnt.grid = 1;
              container.push($('<div />', {'class':'ui-grid-'+current.grid}));
              if (current.grid == 'solo') current.col = 'a';
              prev.grid = current.grid;
              }

          if ((current.col != '') && (current.col != prev.col))
              {
              cc++;
              cnt.col = 1;
              /*****  dirty trick below to force padding on subsequent cols !!!! *****/
              /*** necessary up to jqm 1.3.x ***/
              if (current.col == 'a')
                container.push($('<div />', {'class':'ui-block-'+current.col}));
              else
                container.push($('<div />', {'class':'ui-block-'+current.col}) .css('padding-left', '8px') );
              prev.col = current.col;
             }
          if (value.fieldtype == 'containerset') {
              container.push ($('<div />', {'data-role':'collapsible-set'}));
              cc++;
              return true; // moves to next in $.each
              }
         if (value.fieldtype == 'endcontainerset') {
              container[i-1].append(container[i]);
              cc--;
              return true; // moves to next in $.each
              }
          if ((value.fieldtype == 'container') || (value.fieldtype == 'fieldset'))
             {
              cc++;
              cnt.ctn++;
              // the current container becomes the container for the next loop
              prev.ctn = value.id;
              if (value.fieldtype == 'container') {
                container.push ($('<div />', {'id':value.id, 'data-role':'collapsible', 'data-theme': forminfo.theme || theme,
                           'data-content-theme':forminfo.content-theme || theme,'data-inset':true, 'data-collapsed':  value.collapsed || 'true'} ));
                value.label = value.label || '';
                container[cc].append( $('<H3 />').html(value.label));
                }
              else {
                container.push ($('<fieldset />', {'id':value.id, 'data-role':'collapsible', 'data-theme': forminfo.theme || theme,
                           'data-content-theme':forminfo.content-theme || theme, 'data-inset':true, 'data-collapsed':  value.collapsed || 'true'} ));
                value.label = value.label || '';
                container[cc].append( $('<legend />').html(value.label));

              }
             }
            else

              container[cc].append(that.makefield(value, {'divwrap': true, 'prefix':forminfo.name, 'default': typeof forminfo.default != 'undefined'}));


       });
      } /// end bodyversion = 1
//------------------------------------------------------------------------------------------
      if (bodyversion == 2) {
        $.each (forminfo.body, function ( index, value) {
         value.fieldtype = value.fieldtype.toLowerCase();

          switch (value.fieldtype) {
            case 'hidden':
              container[cc].append(that.makefield(value, {'divwrap': false, 'prefix':forminfo.name}));
              return true; // moves to next in $.each
              break;
           case 'containerset':
             cc++;
             container.push ($('<div />', {'data-role':'collapsible-set'}));
             break;

            case 'fieldset':
                cc++;
                container.push ($('<fieldset />', {'id':value.id, 'data-role':'collapsible', 'data-theme': forminfo.theme || theme,
                           'data-content-theme':forminfo.content-theme || theme, 'data-inset':true, 'data-collapsed':  value.collapsed || 'true'} ));
                value.label = value.label || '';
                container[cc].append( $('<legend />').html(value.label));

              break;
            case 'container':
                cc++;
                container.push ($('<div />', {'id':value.id, 'data-role': value.role || 'collapsible', 'data-theme': forminfo.theme || theme,
                           'data-content-theme':forminfo.content-theme || theme,'data-inset':true, 'data-collapsed':  value.collapsed || 'true'} ));
                value.label = value.label || '';
                container[cc].append( $('<H3 />').html(value.label));
              break;
            case 'controlgroup':
              cc++;
              container.push($('<div />', {'data-role':'controlgroup'}));
              break;
            case 'grid':
              cc++;
              container.push($('<div />', {'class':'ui-grid-'+value.type}));
              break;
            case 'col':
              cc++;
              if (typeof value.role == 'undefined')
                container.push($('<div />', {'class':'ui-block-'+value.type}));
              else
                container.push($('<div />', {'class':'ui-block-'+value.type, 'data-role': value.role}));
              break;

            case '/col':
            case '/grid':
            case '/fieldset':
            case '/controlgroup':
            case '/container':
            case '/containerset':
                container[cc-1].append(container[cc]);
                container.pop();
                cc--;
              break;
            default:
              var required = false;
              if (forminfo.validationrules[value.name]) {
                var valrules= forminfo.validationrules[value.name];
                required = valrules.indexOf('required') != -1;
                if (required)
                  var x=99;
              }

              container[cc].append(that.makefield(value, {required: value.required || required, 'divwrap': true,
                  'prefix':forminfo.name, 'default': typeof forminfo.default != 'undefined'}));
              if (value.oninit)
                functionlist.push( value.oninit );
          }
       });
      } /// end bodyversion 2

    for (i = container.length-1; i>0; i--)
        container[i-1].append(container[i]);

    p.append(container[0]);
    }
    // build the footer
    // very simple and crude for now
    // optionnally builds a navbar inside the footer

    if (typeof forminfo.footer != 'undefined')
    {
    c = $('<div />',{ 'data-role': 'footer', 'data-position': 'fixed',  "id":this.namespace+'footer'});

      if (forminfo.footer.label)
        c.append($('<h3 />').html(forminfo.footer.label));
     if (forminfo.footer.navbar)
      $.each (forminfo.footer.navbar, function ( index, value)
       {
          c.append(that.makefield(value),{});
       });
      p.append(c);
    }
    this.validationrules = forminfo.validationrules || {};
    this.validationmessages = forminfo.validationmessages || {};

    // and create the page
    p.append('<div data-role="popup" style="max-width:300px; min-width:120px"  class="ui-content" data-history=false data-transition="flow"></div>');
    if (typeof manualhtml !== 'undefined')
       p.append(manualhtml);
    p.appendTo($('body'));
    $('#'+ pagedest).page();

    if (functionlist.length !== 0) {
      for (var i=0; i<functionlist.length; i++) {
        eval(functionlist[i]);
      }
    }

   $.mobile.loading('hide');
   $.mobile.changePage($('#'+pagedest));

  };

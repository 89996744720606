;
( function( $, window, document, undefined ) {
  "use strict";
  $.ogcalendar = function( element, options ) {

    var defaults = {
        locale: 'fr',
        currentDate: '',
        mode: 'calendar', // can be calendar | month
        onDisplay: function() {},
        CurrentProductId: -1
      },
      currentElement = element,
      plugin = this;
    plugin.settings = {};
    plugin.$element = $( element );
    plugin.element = element;

    var init = function() {

      console.log( 'this is calendar.init' );
      var html = '',
        dn;
      if ( moment === undefined ) {
        console.log( 'ogcalendar needs moment to be loaded' );
        return;
      }
      plugin.settings = $.extend( {}, defaults, options );

      moment.locale( plugin.settings.locale );
      html = '<div class ="ogcalendar">' +
        '<div class="ogcalendar_onemonth">' +
        '<div class="ogcalendar_title">' +
        '   <div class="ogcalendar_title_before"><i class="fa fa-angle-double-left" aria-hidden="true"></i></div>' +
        '   <div class="ogcalendar_title_month"> Décembre 2017</div>  ' +
        '   <div class="ogcalendar_title_after"><i class="fa fa-angle-double-right " aria-hidden="true"></i> </div> ' +
        '</div>' +
        '<ul class="ogcalendar">';
      dn = moment.localeData();
      for ( var i = 0; i < 7; i++ ) {
        html += '<li class="journame journame' + pad( i, 2 ) + ' "> ' + dn._weekdaysMin[ ( i + 1 ) % 7 ] + ' </span></li>'
      }
      for ( i = 1; i <= 31; i++ ) {
        html += '<li class="jour jour' + pad( i, 2 ) + '"><span class="nojour">' + i + '</span><div class="contentjour"></div></li>';
      }
      html += "</ul></div></div></div>";
      plugin.$element.html( html );
      $( '.ogcalendar_title_before', plugin.$element ).on( 'click.ogeane', function( e ) {
        e.preventDefault();

        var event = $.Event ("oge:monthscroll");
        $(document).trigger(event, { element: plugin.$element } )
        plugin.showCalendar( $( this ).data( 'month' ) )
      } );
      $( '.ogcalendar_title_after', plugin.$element ).on( 'click.ogeane', function( e ) {
          e.preventDefault();
          var event = $.Event ("oge:monthscroll");
          $(document).trigger(event, { element: plugin.$element } )
          plugin.showCalendar( $( this ).data( 'month' ) )

        } );

      if ( plugin.settings.currentDate === '' )
        plugin.settings.currentDate = moment().format( 'YYYY-MM-DD' );
      plugin.showCalendar( plugin.settings.currentDate );
    }
    plugin.id = function( val ) {
      if ( val === undefined )
        return plugin.settings.CurrentProductId;
      else {
        plugin.settings.CurrentProductId = val;
        plugin.showCalendar();
      }
    }
    plugin.showCalendar = function( thisMonth ) {
      var plugin = this,
        currentDate = thisMonth == undefined ? moment() : moment( thisMonth ),
        currentDay = 0,
        calTitle = currentDate.format( 'MMMM YYYY' ),
        startDate = moment( currentDate ).startOf( 'month' ),
        startWeekDay = parseInt( startDate.format( 'd' ) ),
        endDate = moment( currentDate ).endOf( 'month' ),
        endWeekDay = parseInt( endDate.format( 'd' ) ),
        lastDay = endDate.daysInMonth(),
        $ulelt = $( 'div.ogcalendar ul', plugin.$element ),
        html = '',
        i = 0,
        j = 0;
      plugin.settings.currentDate = currentDate;
      $ulelt.css( 'display', 'none' )
      //cleanup
      $( 'div.ogcalendar li.ogcalendar_off', plugin.$element ).remove();
      $( 'div.ogcalendar li', plugin.$element ).removeClass( 'ogcalendar_booked ogcalendar_repair ogcalendar_waiting ' ).addClass( 'ogcalendar_free' ).css( 'display', 'block' );
      $( '.ogcalendar_title_month', plugin.$element ).text( calTitle );
      $( '.ogcalendar_title_before', plugin.$element ).data( 'month', moment( currentDate ).add( -1, 'month' ).format( 'YYYY-MM-DD' ) );
      $( '.ogcalendar_title_after', plugin.$element ).data( 'month', moment( currentDate ).add( 1, 'month' ).format( 'YYYY-MM-DD' ) );
      // Hide all extraneous days
      for ( i = lastDay + 1; i < 32; i++ ) {
        $( 'div.ogcalendar li.jour' + i, plugin.$element ).css( 'display', 'none' );
      }
      $( '.nojour', plugin.$element ).css( 'background-color', 'black' ).parent().removeData( );
      // insert all out elements from previous month
      if ( startWeekDay != 1 ) {
        if ( startWeekDay == 0 ) startWeekDay = 7;
        startDate.add( ( startWeekDay - 1 ) * -1, 'days' )
        currentDay = startDate.format( 'D' );
        for ( i = 0; i < ( startWeekDay - 1 ); i++ ) {
          html += '<li class="jour ogcalendar_off" ><span class="nojour">' + currentDay + '</span></span></li>';
          currentDay++;
        }
        $( 'div.ogcalendar ul li:eq(7)', plugin.$element ).before( html );
      }

      // append all out elements from next month
      if ( endWeekDay != 0 ) {
        html = '';
        j = 1;
        for ( i = endWeekDay; i < 7; i++ ) {
          html += '<li class="jour ogcalendar_off"><span class="nojour">' + ( j++ ) + '</span></li>';

        }
        $( 'div.ogcalendar ul li:last', plugin.$element ).after( html );
        $('.nojour', plugin.$element ).removeClass('calselected');
      }
      if ( plugin.settings.CurrentProductId != -1 )
        getServerData( currentDate );
      $ulelt.css( 'display', 'block' )
    };
    /*
     * @param thisMonth is a moment object
     */
    var getServerData = function( thisMonth ) {

      $.ajax( {
          type: "post",
          global: false,
          context: plugin,
          url: glb.mainurl,
          data: {
            'ogm': "hardware",
            'ogs': 'getbookings',
            'linkvalue': plugin.settings.CurrentProductId,
            'period': thisMonth.format( 'YYYY-MM-DD' )
          }
        } )
        .done( function( jsonData, status, jqxHr ) {
          //plugin.publishersList.removeAll();
          //   jsondata=[{id:1, name:'didier', address:'88 avenue François Mitterrand','post_code':'62930', town:'Wimereux'},
          // {id:2, name:'Olivier', address:'3 rue Jean Baptiste Delaporte','post_code':'62000', town:'Arras'}]
          this.jsonData = jsonData;
          var self = this,
            m = self.$element.data( 'month' ),
            newClass = '',
            currentDay,
            currentText;

          jsonData.forEach( function( day ) {
            newClass = '';
            // if ( day.status == 0 ) newClass = "ogcalendar_free";
            if ( day.status == 1 ) newClass = "ogcalendar_waiting";
            if ( day.status == 2 ) newClass = "ogcalendar_booked";
            if ( day.status == 3 ) newClass = "ogcalendar_repair";

                 currentDay = $( '.jour' + moment( day.calendarday ).format( 'DD' ), self.element );
            if ( day.request_id != -1){
                 currentDay.prop('title', day.request_no + ' ' + day.company_name); // request & company name
                 currentDay.data( 'requestid', day.request_id )
                 currentDay.data( 'requestno', day.request_no )
                 currentDay.data( 'company', day.company_name )
                 currentDay.data( 'status', day.status )

                }
            if ( newClass != '' )
              currentDay.addClass( newClass );
            currentText = currentDay.find( '.contentjour' ).html()



          } );
        } )
        .fail ( function (jqXHR, textStatus, errorThrown) {
           handleAjaxErrors ( jqXHR )
        });
    }
    init();
  }

}) ( jQuery, window, document );

"use strict";

/*
V 1.0.1 OGM JAN 2016 Add check and console messages in changePage
V 1.1.0 DGM MAY 2017 Add autoloading of plugins
V 1.2.0 DGM SEP 2017 Add handling of History
V 1.2.1 DGM OCT 2017 Bug Fix : remove OGESHOW From all divs in the containerHash

this library allows you to have multiple pages in a single HTML file, to create single-page-applications.
To do so you must have multiple elements inside your JS and HTML file.

First here's what you need in your JS file :

oge = new ogePage();

This will initiate the "ogePage" object. You also need to add a reference in your HTML document in order to link them.

<script src="pathtoyourproject/js/01-og-spa.js"></script>
<link rel="stylesheet" type="text/css" href="pathtoyourproject/css/ogePage.css"/>

You need Jquery in order for this Library to work

I / Automatically

You can make it so it automatically handles the page Changing on hash change.

app.js :

$(window).on('hashchange', function(){
  oge.changePage(window.location.hash)
})

index.html :

<div class="testHeader">
	<a href="#pageTest">go to test Page</a>
	<a href="#pageWelcome">go to Welcome Page</a>
</div>
<div class="ogeContainer">
	<div class="ogePage ogeShow" id="pageWelcome">
		<h1>Welcome to this website !</h1>
	</div>
	<div class="ogePage" id ="pageTest">
		<h1>It Works ! you changed it !</h1>
	</div>
</div>

With this, you should have a mini single page app. And you can also see that if you change Page,
you can return to the previous one using your browser's "previous page" button.

II / Manually

You can manually call the changePage function. changePage is the function which switches your pages.
It does so using a hash you provide in the function, like this :

changePage("#yourPageDiv")

This method doesn't rely on page hash and doesn't change it.

III / Handling Events

You can , on your js file, handle the "BeforeOgChangePage" and "AfterOgChangePage".

They Allow your to perform actions before and after changing Page, you can for example prevent changing Page if something is not ready

this will be something like :

$(document).on("BeforeOgChangePage",function(){
   Your code here;
});

Some things :
You can see that there is a Container around the pages (ogeContainer class).
This is to place the pages properly in relation to other elements in the HTML file.
If you don't want to have a header or other things that won't change, you can discard this class.

You also don't need to have a specific class for the links you use, because this library makes use of the fact that the hash changes when you click on links
What you do need to do, however, is put the "ogeShow" class on your default page.

*/

function ogePage(mainContainerId) {
  var defaultHash = '#'+$('.ogepage.ogeshow').attr('id')
    this.currentPage={};
    this.previousPage={};
    this.futurePageid='';
    this.defaultContainerId = mainContainerId || "#masterContainer";

    //record current page & url
    history.pushState( {
        'emitter':'ogeane',
        'container': this.defaultContainerId,
        'toPage' : defaultHash
     }, $(document).attr('title'), window.location);

    return this;
}

/* Setters for the Pages, you can do them individually or both at the same time */

ogePage.prototype.setCurrent = function(currentPage, containerId) {
    containerId = containerId || this.defaultContainerId;
    this.currentPage[containerId] = currentPage;
}

ogePage.prototype.setPrevious = function(previousPage, containerId) {
    containerId = containerId || this.defaultContainerId;
    this.previousPage[containerId] = previousPage;
}

ogePage.prototype.setPages = function(previousPage, currentPage, containerId) {
    containerId = containerId || this.defaultContainerId;
    currentPage = currentPage.charAt(0)=='#' ? currentPage.slice(1) : currentPage;
    this.currentPage[containerId] = currentPage;
    previousPage = previousPage.charAt(0)=='#' ? previousPage.slice(1).trim() : previousPage.trim();
    this.previousPage[containerId] = previousPage;
}

/* getters for the pages */

ogePage.prototype.getCurrent = function(containerId){
    containerId = containerId || this.defaultContainerId;
	return this.currentPage[containerId];
}


ogePage.prototype.getPrevious = function(containerId){
    containerId = containerId || this.defaultContainerId
	return this.previousPage[containerId];
}

/*
V 1.2.0 * containerHash can be either undefined or null which is the same now
        * if payload.history does not exists a new entry in window.history is made
           it contains an object with the hash of the page (after the afterChangeEvent)
        * Added automatic handling of window.onpopstate event
        * Added automatic registry inside oge
}
 }

V 1.1.0 load plugin mecanism
// Now implements a deferred object to execute the second stage (after all checks performed)
// which is firing beforeChangePage, hiding the current page, showing the new and trigerring the afterPageChange
// This allows the ensure that the plugin (if any) is fully loaded before proceeding

V 1.0.0 Initial release

/* Change Page function, to switch the page depending on page hash.
Also initializes the currentPage and previousPage variables on first change
You can also, on your js file, handle the "BeforeOgChangePage" and "AfterOgChangePage".

They Allow your to perform actions before and after changing Page, you can for example prevent changing Page if something is not ready

$(document).on("BeforeOgChangePage",function(){
   Your code here;
});
*/

ogePage.prototype.changePage = function(hash, containerHash, payload) {
    var self=this,
    deferred, returnResult = true,
        futurePageid = hash.charAt(0)=='#' ? hash.slice(1).trim() : hash.trim(),
     previousPage, currentPage,
     payload = payload || {"pluginid": "" }
     if ( payload.history === undefined )
         payload.history = true;

    containerHash = containerHash ||  this.defaultContainerId;
    if ( ($(containerHash).length == 0 ) || (!  $(containerHash).hasClass ('ogecontainer')) ) {
        console.log("ogepage.changePage : containerHash: " + containerHash + " doesn't exist or is not a valid container")
        return false;
        }

    previousPage = $('.ogepage.ogeshow', containerHash).attr("id");

    if (typeof previousPage === 'undefined' ) {
        console.log("ogePage.changePage previousPage "+previousPage+" is undefined inside "+containerHash);
        //return false;
        previousPage = 'thereisnopreviouspage';
    }

    if ( $(hash, containerHash) === undefined) {
      console.log("ogePage.changePage hash "+hash+" is undefined or doesn't belong to "+containerHash);
      return false;
    }


    returnResult = true;
    deferred = $.Deferred()

    if (( payload.pluginid || '' ) != '') {
      loadPlugin (payload.pluginid, deferred)
    } else {
      deferred.resolve()
    }

    deferred.done (function () {
      var eventBefore = $.Event ("oge:beforeChangePage");

      $(document).trigger(eventBefore,
           { fromPage: previousPage, toPage: futurePageid, container:containerHash } )
      if ( (eventBefore.result == false) || (eventBefore.isDefaultPrevented() )) {
          returnResult = false
        } else {
          // V 1.2.1
          $('div.ogepage.ogeshow ', containerHash).removeClass("ogeshow")
          // $('#'+previousPage).removeClass("ogeshow")
          //
          self.setPages(previousPage, hash, containerHash);
          $(hash, containerHash).addClass("ogeshow");
          $(document).trigger("oge:afterChangePage", { fromPage: currentPage, toPage: futurePageid, container:containerHash });
          returnResult = true;
          if ( payload.history )
            history.pushState( {
                'emitter':'ogeane',
                'container': containerHash,
                'toPage' : hash
             }, null, "/"+futurePageid);

          }
        })
      .fail ( function () { returnResult = false;} )

      return returnResult;
};

/* allows the user to prevent or enable the changePage function

You can for example put a checkbox that prevents the user from changing page unless it's checked : Here's the example input :

<input type="checkbox" id="checkTest">

And the code in javascript (using Jquery)

$(window).on('hashchange', function(){
  if ($("#checkTest").prop('checked')){
  	oge.enableChange();
  }
 	else {
  	oge.preventChange();
 }
  oge.changePage(window.location.hash)
})

*/

ogePage.prototype.preventChange = function() {
    this.evtBef.returnValue = false;
}

ogePage.prototype.enableChange = function() {
    this.evtBef.returnValue = true;
}

if (! window.glb )
  window.glb = {}

if ( glb.oge === undefined )
   glb.oge= new ogePage();
if ( glb.ogeEvents === undefined)
  glb.ogeEvents= {
    before: {},
    after: {}
  };
if ( glb.plugins === undefined )
     glb.plugins= {};


window.addEventListener('popstate', function (event) {
  var content = "";
  console.log("origin: " + document.origin + ", state: " + JSON.stringify(event.state));
  if ((event.state) && (event.state.emitter === 'ogeane')) {
    console.log('going back to ' + event.state.toPage)
    glb.oge.changePage(  event.state.toPage,  event.state.container, { history:false })
  }

})
